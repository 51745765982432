import {
  Links,
  Meta,
  Scripts,
  ScrollRestoration,
  useRouteLoaderData,
} from '@remix-run/react';
import { type PropsWithChildren, useEffect } from 'react';
import {
  PreventFlashOnWrongTheme,
  type Theme,
  ThemeProvider,
  useTheme,
} from 'remix-themes';
import { env } from '~/config/env';
import { cn } from '~/utils/cn';

export function Layout({ children }: PropsWithChildren) {
  const data = useRouteLoaderData<{ theme: string | null }>('root');
  let theme = data?.theme;

  if (typeof window !== 'undefined') {
    if (theme) {
      localStorage.setItem('theme', theme);
    } else {
      theme = localStorage.getItem('theme');
    }
  }

  return (
    <ThemeProvider specifiedTheme={theme as Theme} themeAction="/api/set-theme">
      <InnerLayout ssrTheme={Boolean(theme)}>{children}</InnerLayout>
    </ThemeProvider>
  );
}

interface InnerLayoutProps {
  ssrTheme: boolean;
}

function InnerLayout({
  ssrTheme,
  children,
}: PropsWithChildren<InnerLayoutProps>) {
  const [theme] = useTheme();

  useEffect(() => {
    injectGTMScript(env.VITE_GTM_ID);
  }, []);

  return (
    <html lang="en" className={cn(theme)}>
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>{env.VITE_APP_NAME}</title>
        <Meta />
        <Links />
        <PreventFlashOnWrongTheme ssrTheme={ssrTheme} />
        <script
          dangerouslySetInnerHTML={{
            __html: `
              (function(h,o,t,j,a,r){
                h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                h._hjSettings={hjid:${env.VITE_HOTJAR_ID},hjsv:6};
                a=o.getElementsByTagName('head')[0];
                r=o.createElement('script');r.async=1;
                r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                a.appendChild(r);
              })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
            `,
          }}
        />
      </head>
      <body>
        <noscript>
          <iframe
            title="GTM"
            src={`https://www.googletagmanager.com/ns.html?id=${env.VITE_GTM_ID}`}
            height="0"
            width="0"
            style={{ display: 'none', visibility: 'hidden' }}
          />
        </noscript>
        {children}
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

let gtmScriptInjected = false;

function injectGTMScript(GTM_ID: string) {
  if (!GTM_ID || gtmScriptInjected) {
    return;
  }

  // Code copied from GTM console + added type annotations.
  ((w: Window, d: Document, s: 'script', l: string, i: string) => {
    w[l] = w[l] || [];
    w[l].push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js',
    });
    const f = d.getElementsByTagName(s)[0];
    const j = d.createElement<'script'>(s);
    const dl = l !== 'dataLayer' ? `&l=${l}` : '';
    j.async = true;
    j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`;
    f.parentNode?.insertBefore(j, f);
  })(window, document, 'script', 'dataLayer', GTM_ID);

  gtmScriptInjected = true;
}
