import type {
  HTTPClient,
  HTTPClientError,
  HTTPClientResponseChain,
} from '../index';
import type { ValidAddonContext } from '../addons';
import { getResolverForContentType } from './get-resolver-for-content-type';

export interface CreateUnauthorizedCatcherOptions<
  RefreshAuthResult,
  Addons,
  Resolvers,
> {
  refreshAuth: (params: {
    request: Addons & HTTPClient<Addons, Resolvers>;
  }) => Promise<RefreshAuthResult> | RefreshAuthResult;
  beforeResolve?: (params: {
    request: Addons & HTTPClient<Addons, Resolvers>;
    refreshAuthResult: RefreshAuthResult;
  }) =>
    | Promise<Addons & HTTPClient<Addons, Resolvers>>
    | (Addons & HTTPClient<Addons, Resolvers>);
  resolve?: (params: {
    request: Addons & HTTPClient<Addons, Resolvers>;
    refreshAuthResult: RefreshAuthResult;
    resolver: Resolvers & HTTPClientResponseChain<Addons, Resolvers>;
  }) => unknown;
  onRetryUnauthorized?: (params: {
    request: Addons & HTTPClient<Addons, Resolvers>;
    error: HTTPClientError;
    refreshAuthResult: RefreshAuthResult | null;
  }) => Promise<void> | void;
}

/**
 * Helper function for creating 401 catchers for HTTPClient.
 * Takes care of retrying the failed requests after the auth refresh
 * function is done, and handling the error in case of failure.
 *
 * ### Example
 *
 * ```ts
 * import { createUnauthorizedCatcher } from '@medizzy/http-client';
 *
 * const refreshAccessTokenCatcher = createUnauthorizedCatcher({
 *   refreshAuth: async ({ request }) => {
 *     const token = await refreshAccessToken();
 *     return token;
 *   },
 *   beforeResolve: ({ request, refreshAuthResult }) => {
 *     return request.auth(`Bearer ${token}`);
 *   },
 *   onRetryFailed: () => {
 *     throw new Error('Authentication required');
 *   }
 * });
 * ```
 *
 * @param refreshAuth - function implementing auth refresh logic. Its return value is passed to other handlers.
 * @param beforeResolve - function called before resolving with the response. Can be used to modify the response chain.
 * @param resolve - function implementing custom resolving logic for the retried request. If you use a custom resolve
 * function, then the `onRetryFailed` hook **will NOT be called automatically**.
 * @param onRetryUnauthorized - function called in case the retried request fails.
 */
export function createUnauthorizedCatcher<T, A = unknown, R = unknown>({
  refreshAuth,
  beforeResolve,
  resolve,
  onRetryUnauthorized,
}: CreateUnauthorizedCatcherOptions<T, A, R>) {
  return async (_: HTTPClientError, request: A & HTTPClient<A, R>) => {
    let refreshAuthResult: T;

    try {
      refreshAuthResult = await refreshAuth({ request });
    } catch (error) {
      if (!onRetryUnauthorized) {
        throw error;
      }

      return onRetryUnauthorized({
        request,
        error: error as HTTPClientError,
        refreshAuthResult: null,
      });
    }

    const finalRequest =
      (await beforeResolve?.({ request, refreshAuthResult })) ?? request;

    if (resolve) {
      // If the resolve function was passed use it to resolve the retried request
      return finalRequest
        .resolve((resolver) => {
          return resolve({ request, refreshAuthResult, resolver });
        })
        .fetch();
    }

    return finalRequest
      .resolve(async (resolver) => {
        // The catcher has no way of knowing which resolver was called on the initial request.
        // Because of that, we first have to "guess" the correct data resolver based on the
        // response Content-Type header.
        const response = await resolver._fetchReq;
        const contentType = response.headers.get('content-type');
        const method = getResolverForContentType(contentType);
        const resolveFn = resolver[method];

        resolver.unauthorized(async (error, failedRequest) => {
          if (!onRetryUnauthorized) {
            throw error;
          }

          await onRetryUnauthorized({
            error,
            request: failedRequest,
            refreshAuthResult,
          });
        });

        if ('valid' in request._options) {
          // Because valid addon acts as a resolver, it also won't be called by the catcher
          // once the request is replayed. Because of that, the addon stores the schema
          // and preprocess functions in the _options property of the request.
          // This way we can read them, and re-apply them in the catcher.
          const {
            valid: { schema, preprocess },
          } = request._options as ValidAddonContext;

          return resolveFn((data) => schema.parse(preprocess(data)) as unknown);
        }

        return resolveFn();
      })
      .fetch();
  };
}

/**
 * When storing a catcher in a variable, its client type is inferred to `HTTPClient<unknown, unknown>`.
 * Then, when we pass it to an actual catcher, which utilises addons, we will receive a type error
 * because there is no intersection between unknown and the addon types.
 * To mitigate this issue, you can use this helper type, which casts the client param to
 * `HTTPClient<any, any>`, making it easier to reuse catchers across client instances
 */
export type GenericUnauthorizedCatcher = (
  error: HTTPClientError,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- intentional
  client: HTTPClient<any, any>,
) => unknown;
